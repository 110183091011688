$size-sm: 992px;
$size-md: 768px;
$size-lg: 992px;
$size-xl: 1200px;

$primary: #FFFFFF;
$primary-1: #F8F9FE;
$primary-2: #DFE0E5;
$primary-3: #C6C7CB;
$secondary: #0A242E;
$darkest-grey: #323232;
$dark-grey: #707070;
$dark-blue: #0A242E;
$blue: #374678;
$light-blue: #6E8BEF;
$lightest-blue: #b7c5f7;
$lightest-blue-transparent: rgba($color: $light-blue, $alpha: 0.2);
$purple: #4e0175;
$red: #EE6869;
$yellow: #FD9326;
$green: #77CEC7;

$accent: var(--accent-1);
$background: var(--accent-2);
$buttonFont: var(--font-color);

$transition: all 0.3s ease;

$shadow-none: 0 0 0 rgba(0, 0, 0, 0);
$shadow-sm: 0 0 0.25em rgba(0, 0, 0, 0.1);
$shadow: 0 0 0.5em rgba(0, 0, 0, 0.1);
$shadow-lg: 0 0em 1em rgba(0, 0, 0, 0.1);
$shadow-button: 0 0em 0.5em rgba(0, 0, 0, 0.3);
$shadow-outline: 0 0 0 0.2em
  rgba(
    $color: $light-blue,
    $alpha: 0.2,
  );
$radius-xs: 5px;
$radius-sm: 10px;
$radius: 15px;
$radius-lg: 25px;
$radius-xl: 40px;

$sidenav-width: 300px;
$sidenav-width-collapsed: 70px;

$topnav-height: 70px;

$fademask-height: 50px;

$scrollbar-width: 8px;

//$form-floating-height:            3em;
//$form-floating-line-height:       1.5;