// MULISH FONT
/* 200 */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-ExtraLight.ttf') format('truetype');
}
/* 300 */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-Light.ttf') format('truetype');
}
/* 400 */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-Regular.ttf') format('truetype');
}
/* 500 */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-Medium.ttf') format('truetype');
}
/* 600 */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-SemiBold.ttf') format('truetype');
}
/* 700 */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-Bold.ttf') format('truetype');
}
/* 800 */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-ExtraBold.ttf') format('truetype');
}
/* 900 */
@font-face {
  font-family: 'Mulish';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/mulish/Mulish-Black.ttf') format('truetype');
}