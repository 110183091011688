@import "./variables";

.ct-card {
    position: relative;
    padding: 1em 1em 0 1em;
    border-radius: $radius;
    background-color: $primary;
    box-shadow: $shadow-sm;
    
    h5 {
        font-weight: 600;
    }
}