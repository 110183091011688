@import "./variables";

$enable-shadows: true;

.ct-btn,
.tour-buttons button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  padding: 0.7em 1.6em;
  border: none;
  border-radius: $radius-sm;
  box-shadow: $shadow-sm;
  transition: $transition;
  font-weight: 400;
  @media (hover: hover) {
    &:hover {
      box-shadow: $shadow-button;
    }
  }
  &:active {
    transform: scale(0.95);
    box-shadow: $shadow-none;
  }
  &:disabled,
  &.disabled {
    color: $primary-3;
    background: $secondary;
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
    @media (hover: hover) {
      &:hover {
        background-color: $secondary;
        color: $primary-3;
        box-shadow: none;
      }
    }
    &:active {
      transform: scale(1);
    }
  }
  &.ct-btn-round {
    border-radius: 50%;
    padding: 0.7em;
    width: fit-content;
    height: fit-content;
    .icon-wrapper {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.ct-btn-rounded {
    border-radius: 1000px;
  }
  $icon-margin: 0.5em;
  &.icon-start fa-icon {
    margin-right: $icon-margin;
  }
  &.icon-end fa-icon {
    margin-left: $icon-margin;
  }
  &.form-group-height {
    height: 3.5em;
  }
  &.dropdown-btn {
    //padding: 0 0 0 1.6em;
    padding: 0;
    @media (hover: hover) {
      &:hover {
        span {
          border-left-color: $primary;
        }
      }
    }

    div {
      padding: 0.7em 1.6em;
    }

    span {
      &:not(.spinner-border) {
        padding: 0.7em;
        //margin-left: 1.6em;
        border-left: solid thin $secondary;
        text-align: center;
        border-top-right-radius: $radius-sm;
        border-bottom-right-radius: $radius-sm;
        transition: $transition;
        @media (hover: hover) {
          &:hover {
            background-color: rgba(0, 0, 0, 0.5);

            fa-icon {
              transform: scale(1.1);
            }
          }
        }
        fa-icon {
          margin: 0;
        }
      }
    }
  }

  span {
    &.spinner-border {
      width: 1em;
      height: 1em;
      border-width: 0.15em;
      margin-left: 0.5em;
    }
  }
}

ng-select.ct-btn {
  &:active {
    transform: none;
    box-shadow: none;
  }
}

.ct-btn-blue {
  color: white;
  background: $blue;
  background: linear-gradient(90deg, $light-blue 0%, $blue 100%);
}

.ct-btn-blue-solid {
  color: white;
  background-color: $light-blue;
  @media (hover: hover) {
    &:hover {
      background-color: darken($light-blue, 5%);
    }
  }
}

.ct-btn-red {
  color: white;
  background-color: $red;
  @media (hover: hover) {
    &:hover {
      background-color: darken($red, 5%);
    }
  }
}

.ct-btn-green {
  color: white;
  background-color: $green;
  @media (hover: hover) {
    &:hover {
      background-color: darken($green, 5%);
    }
  }
}

.ct-btn-grey {
  color: $primary;
  background-color: $secondary;
  @media (hover: hover) {
    &:hover {
      filter: brightness(0.95);
    }
  }
}

.ct-btn-white-blue {
  color: $blue;
  background: $primary;
  background: linear-gradient(90deg, white 0%, white 100%);
  @media (hover: hover) {
    &:hover {
      color: white;
      background: $blue;
      background: linear-gradient(90deg, $light-blue 0%, $blue 100%);
    }
  }
  &.active {
    color: white;
    background: $blue;
    background: linear-gradient(90deg, $light-blue 0%, $blue 100%);
  }
}

.ct-btn-white-blue-solid {
  color: $light-blue;
  background-color: $primary;
  @media (hover: hover) {
    &:hover {
      color: white;
      background-color: darken($light-blue, 5%);
    }
  }
  &.active {
    color: white;
    background-color: darken($light-blue, 5%);
  }
}

.ct-btn-white-red {
  color: $red;
  background-color: $primary;
  @media (hover: hover) {
    &:hover {
      color: white;
      background-color: darken($red, 5%);
    }
  }
  &.active {
    color: white;
    background-color: darken($red, 5%);
  }
}

.ct-btn-white-green {
  color: $green;
  background-color: $primary;
  @media (hover: hover) {
    &:hover {
      color: white;
      background-color: darken($green, 5%);
    }
  }
  &.active {
    color: white;
    background-color: darken($green, 5%);
  }
}

.ct-btn-white-grey {
  color: $secondary;
  background-color: $primary;
  @media (hover: hover) {
    &:hover {
      color: $primary;
      background-color: $secondary;

      .ng-placeholder {
        color: $primary !important;
      }
    }
  }
  &.active {
    color: $primary;
    background-color: $secondary;

    .ng-placeholder {
      color: $primary !important;
    }
  }
}

.ct-btn-themed {
  color: $accent;
  background-color: $buttonFont;
  &:hover {
    color: $background;
    background-color: $buttonFont;
  }
}

.ct-btn-themed-solid {
  color: $buttonFont;
  background-color: $accent;
  &:hover {
    color: $buttonFont;
    background-color: $accent;
  }
}