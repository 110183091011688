@import "./assets/scss/fonts";

@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/spinners";

@import "./assets/scss/buttons";
@import "./assets/scss/cards";
@import "./assets/scss/forms";
@import "./assets/scss/scrollbar";

@import "./assets/scss/variables";
@import "./assets/scss/reset";
@import "./assets/scss/bootstrap-bugfixes";

body {
  padding: 0;
  margin: 0;
  font-family: "Mulish", sans-serif;
  color: $secondary;
  background-color: $accent;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  padding-top: min(10%, 50px);
  padding-bottom: env(safe-area-inset-bottom);

  @supports (height: 100dvh) {

    height: 100dvh;

  }

  a {
    color: $accent;
    text-decoration: inherit;
    transition: $transition;

    &:hover {
      color: $blue;
    }
  }

  button {
    font-size: 1em;
  }

  code {
    color: inherit;
  }

  h2 {
    font-size: 1.5em;
    font-weight: 400;
    margin: 0;
    min-height: 1.5em;
  }

  h3 {
    font-size: 2.5em;
    font-weight: 600;
    margin: 0;
  }
}

@keyframes zoom-in {
  to {
    transform: scale(1.05);
  }
}

@keyframes zoom-out {
  from {
    transform: scale(0.95);
  }
}

::view-transition-old(view),
::view-transition-new(view) {
  animation-duration: 0.3s;
  animation-name: -ua-view-transition-fade-in, zoom-out;
}

::view-transition-old(view) {
  animation-name: -ua-view-transition-fade-out, zoom-in;
}