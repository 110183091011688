@import "./variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: none !important;
  }
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

ol,
ul {
  padding: 0;
}
