@import "bootstrap/scss/mixins";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/_forms.scss";
@import '@angular/cdk/overlay-prebuilt.css';

@import "./variables";

.form-control,
.dp-picker-input {
  border: solid thin $primary-2;
  border-radius: $radius-sm;
  box-shadow: none;
  padding: 0.7em 1em;
  height: calc(3.5em + 2px);
  background-color: $primary;
  color: $secondary;

  &:focus,
  &.ng-select-focused {
    box-shadow: none;
    border-color: $secondary;
    background-color: $primary;
    color: $secondary;

    &.ng-select-searchable {
      .ng-placeholder {
        color: $primary-3;
      }
    }
  }

  .ng-placeholder {
    color: $secondary;
    opacity: 1;
    transition: $transition;
  }
}

.form-check {
  display: flex;
  align-items: center;

  @media (max-width: $size-lg) {
    flex-grow: 1;
  }

  .form-check-input {
    margin-top: 0;
  }
}

.form-group {
  position: relative;
  margin-bottom: 1.5em;

  &.invalid {

    .form-control,
    ct-lib-markdown-editor .ql-container,
    ct-lib-captcha,
    ct-lib-friendly-captcha {
      box-shadow: 0 0 0 2px rgba($color: $red, $alpha: 0.5);
    }

    .validation {
      display: inline-block;

      p {
        color: $red;
      }
    }
  }

  $validation-font-size: 0.7em;

  .validation {
    font-size: $validation-font-size;
    width: 100%;
    display: none;
    text-align: left;
    margin-top: 0.2em;

    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }
  }

  ct-lib-markdown-editor+.validation {
    top: 100%;
  }

  .form-control::placeholder {
    color: $primary-3;
  }

  button {
    height: 3.5em;
  }
}

input {

  &[type="checkbox"],
  &[type="radio"] {
    min-width: 1.25em;
    min-height: 1.25em;
    width: 1.25em;
    height: 1.25em;
    margin: 0;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: thin solid $secondary;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    transition: $transition;
    border-radius: 0.25em;
    cursor: pointer;

    &:checked {
      background-color: $accent;
      border-color: $accent;
    }

    &+label {
      margin-left: 0.5em;
      cursor: pointer;
    }
  }

  &[type="checkbox"] {
    margin: 8px 0 0 0;

    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }

    &:disabled {
      background-color: $primary-3;
      border-color: $primary-3;
    }

    &.ct-switch {
      $label-width: 3em;
      $label-height: 1em;

      display: none;

      &+label {
        position: relative;
        width: $label-width;
        height: $label-height;
        background-color: $primary;
        margin: 0 5px;
        margin-bottom: -2.5px;
        border-radius: 100px;
        transition: $transition;
        cursor: pointer;
        mix-blend-mode: unset;

        &:before {
          content: "";
          position: absolute;
          top: -5px;
          left: -5px;
          width: calc(#{$label-height} + 10px);
          height: calc(#{$label-height} + 10px);
          background-color: $primary-3;
          border-radius: 50%;
          box-shadow: $shadow-sm;
          transition: $transition;
        }
      }

      &:disabled {
        background-color: $primary-3 !important;
        border-color: $primary-3 !important;

        &+label {
          background-color: $primary-2 !important;

          &:before {
            background-color: $primary-3 !important;
          }
        }
      }

      &:checked+label {
        background-color: $blue;

        &:before {
          left: calc(#{$label-width} - #{$label-height} - 5px);
          background-color: $accent;
        }
      }
    }
  }

  &[type="radio"] {
    border-radius: 50%;

    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
    }
  }

  &[type="file"] {
    &.label-only {
      display: none;
    }
  }

  &[type="text"],
  &[type="password"],
  &[type="email"] {
    &:disabled {
      cursor: not-allowed;
      background-color: $primary-2;
    }
  }

  &[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

.ng-select {
  cursor: pointer;
  padding: 0 !important;
  display: inline-block !important;

  &.ng-select-opened .ng-arrow {
    transform: rotateX(180deg);
  }

  &.ng-select-disabled {
    cursor: not-allowed !important;
    background-color: $primary-2 !important;
  }

  &.ng-select-searchable .ng-select-container .ng-input>input {
    &.ng-select-clearable {
      width: calc(100% - 2.5em) !important;
    }
  }

  &.form-facility-select {
    height: auto !important;

    .ng-dropdown-panel {
      top: inherit !important;
    }
  }

  .ng-select-container {
    padding: 1.625rem 0.75rem 0.625rem 0.75rem;

    &.ng-has-value .ng-placeholder {
      display: none;
    }

    .ng-input,
    input {
      padding: 0 0.75rem;
      color: $secondary;
      cursor: pointer !important;

      &:disabled {
        cursor: not-allowed !important;
      }
    }

    .ng-clear-wrapper,
    .ng-arrow-wrapper {
      transform: translateY(-0.25em);
    }

    .ng-arrow-wrapper .ng-arrow {
      height: 14px;
      width: 14px;
      //background-image: url("../img/icons/chevron-down.svg");
      background-repeat: no-repeat;
      transition: $transition;
    }
  }
}

.ng-dropdown-panel {
  left: 0;
  top: 4em;
  background-color: $primary;
  color: $secondary;
  border-radius: $radius;
  box-shadow: $shadow-sm;
  animation: dropdownAnimation 0.3s ease forwards;
  transform-origin: top center;
  border: 0 !important;

  &.form-control {
    height: auto;
    padding: 0;
    border: none;
    margin-top: 1em;
  }

  .ng-option {
    min-height: 3em;
    line-height: 2em;
    position: relative;
    padding: 0.5em 1em;
    margin: 0.5em;
    border-radius: 10px;
    transition: $transition;
    text-align: left;
    display: flex !important;
    align-items: center;

    &:hover {
      background-color: $primary-1;
    }

    input[type="checkbox"] {
      margin: 0;
      margin-right: 0.5em;
    }
  }
}

@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

.ct-datepicker {
  width: 100%;
  display: block;

  input {
    width: 100%;
  }

  &.dp-popup {
    border: none;
    background: rgba(255, 255, 255, 0);
    box-shadow: none;

    button {
      background: rgba(255, 255, 255, 0);
      border: none;
      color: $secondary;
      transition: $transition;

      &:not(.dp-calendar-nav-left):not(.dp-calendar-nav-right) {
        border-radius: $radius-sm;

        &.dp-current-day {
          background: $secondary;
          color: white;
        }

        &.dp-selected,
        &:hover {
          background: $accent;
          color: white;
        }

        &:active {
          transform: scale(0.95);
        }
      }
    }

    .dp-day-calendar-container,
    .dp-month-calendar-container,
    .dp-time-select-controls {
      background-color: $primary;
      color: $secondary;
      border-radius: $radius;
      box-shadow: $shadow-sm;
      padding: 0.5em;

      .dp-calendar-wrapper,
      .dp-calendar-nav-container {
        border: none;
      }

      .dp-calendar-nav-container {
        padding: 1.5em 0;

        .dp-nav-header button {
          padding: 0.5em;
        }
      }

      .dp-calendar-weekday {
        border-color: $primary-2;
        border-left: none;
        border-right: none;
        width: 37px;
      }

      .dp-calendar-day {
        height: 35px;
        width: 35px;
        margin: 1px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }

      .dp-calendar-day,
      .dp-calendar-month {
        &[disabled] {
          background: $primary-2;
          cursor: not-allowed;

          &:hover {
            background: $primary-2;
            color: $secondary;
          }

          &.dp-next-month,
          &.dp-prev-month {
            opacity: 1;
          }
        }
      }
    }

    .dp-time-select-controls {
      li {
        width: 40px;

        button {
          width: 40px;
          height: 40px;
          line-height: 40px;

          &::before {
            top: 0px;
          }
        }
      }
    }
  }
}

.p-checkbox {
  &.p-checkbox-disabled {
    opacity: 0.3;

    &+label {
      background-color: $primary-2 !important;

      &:before {
        background-color: $primary-3 !important;
      }
    }
  }
}

.input-label {
  position: fixed;
  font-weight: bold;
  transition: $transition;
  opacity: 0;
  transform: translateY(5px) translateX(5px);

  &.active {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
}

.form-floating[class^="col"]>label {
  left: 0.75rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  //opacity: 0.65;
  //transform: scale(0.85) translateY(-2.5rem) translateX(0.15rem);
}

.form-floating>.form-control::placeholder {
  color: transparent !important;
}

.form-floating {
  position: relative;
}

.form-floating>label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ct-switch-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  background-color: $primary;
  border-radius: $radius;
  margin-bottom: 1em;
  box-shadow: $shadow;

  &.disabled {
    cursor: not-allowed;

    p {
      color: $primary-3;
    }
  }

  .ct-switch:not(:checked)+label {
    background-color: $primary-2 !important;
  }
}