@import "./variables";

::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
  z-index: 100;
}
::-webkit-scrollbar-thumb {
  background: $primary-3;
  border-radius: $scrollbar-width*2;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
::-webkit-scrollbar-track {
  //background: rgba($color: $secondary, $alpha: 0.2);
  border-radius: $scrollbar-width;
}
